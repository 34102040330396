import {mapGetters, mapActions, mapMutations} from 'vuex';

import {formatBarcode} from "@/utils/barcode";

export default {
  name: 'barcode',
  data() {
    return {
      keyCodeArray: [],
      inputValue: null,
      isLoading: false
    }
  },
  components: { },
  created() {},
  mounted() {
    document.addEventListener('keydown', this.keyEventReading);
  },
  computed: {
    ...mapGetters({
      rack: 'racks/rack',
      shelf: 'shelves/shelf',
      place: 'places/place',
      parcel: 'parcels/parcel',
    }),
  },
  watch: {},
  methods: {
    ...mapActions({
      fetchRack: 'racks/RACKS_REQUEST_DATA_GET',
      fetchShelf: 'shelves/SHELVES_REQUEST_DATA_GET',
      fetchPlace: 'places/PLACES_REQUEST_DATA_GET',
      fetchParcel: 'parcels/PARCELS_REQUEST_DATA_GET',
      fetchProduct: 'products/PRODUCTS_REQUEST_DATA_GET',
    }),
    ...mapMutations({
      setBarcodeId: 'barcode/BARCODE_ID',
    }),
    clear () {
      this.inputValue = null;
      this.keyCodeArray = [];
    },
    keyEventReading($event) {
      if ($event.keyCode === 13){
        if (this.inputValue && this.inputValue.length > 0) {
          this.parseBarcode(this.inputValue);
        } else {
          this.parseBarcode(formatBarcode(this.keyCodeArray));
          this.keyCodeArray = [];
        }
      } else {
        this.keyCodeArray.push($event);
      }
    },
    parseBarcode(code) {
      let parts = code.toUpperCase().split('-'),
          type = parts[0],
          id = parseInt(parts[1]);
      if (id) {
        switch (type) {
          //---store
          case 'ST': {
            this.getStore(id);
            this.inputValue = code;
            this.$toasted.success(this.$t('barcodeRecognized'));
            break;
          }
          //---racks
          case 'RA': {
            this.getRack(id);
            this.inputValue = code;
            break;
          }
          //---shelves
          case 'SH': {
            this.getShelf(id);
            this.inputValue = code;
            this.$toasted.success(this.$t('barcodeRecognized'));
            break;
          }
          //---places
          case 'PL': {
            this.getPlace(id);
            this.inputValue = code;
            this.$toasted.success(this.$t('barcodeRecognized'));
            break;
          }
          //---parcels
          case 'PA': {
            this.getParcel(id);
            this.inputValue = code;
            this.$toasted.success(this.$t('barcodeRecognized'));
            break;
          }
          //---product
          case 'PR': {
            this.getProduct(id);
            this.inputValue = code;
            this.$toasted.success(this.$t('barcodeRecognized'));
            break;
          }
          //---maybe will be product
          default: {
            this.inputValue = null;
            this.$toasted.error(this.$t('barcodeNotRecognized'));
            break;
          }
        }
      } else {
        this.inputValue = null;
        this.$toasted.error(this.$t('barcodeNotRecognized'));
      }
    },
    getStore(id) {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
        this.setBarcodeId(id);
        this.$router.push({name: 'stores.list'}).catch(() => {console.log()});
      },1000);
    },
    getRack(id) {
      this.isLoading = true;
      this.fetchRack(id).then(()=>{
        this.setBarcodeId(id);
        this.$router.push({
          name: 'racks.list',
          query: {store_id: this.rack.store_id}
        }).catch(() => {console.log()});
      }).catch(error => {
        if (error.response.status === 404) {
          this.$toasted.error(error.response.data.data.message);
        }
      }).finally(()=>{
        this.isLoading = false;
      });
    },
    getShelf(id) {
      this.isLoading = true;
      this.fetchShelf(id).then(() => {
        this.setBarcodeId(id);
        this.$router.push({
          name: 'shelves.list',
          query: {
            store_id: this.shelf.rack.data.store_id,
            rack_id: this.shelf.rack_id,
          }
        }).catch(() => {console.log()});
      }).catch(error => {
        if (error.response.status === 404) {
          this.$toasted.error(error.response.data.data.message);
        }
      }).finally(() => {
        this.isLoading = false;
      });
    },
    getPlace(id) {
      this.isLoading = true;
      this.fetchPlace(id).then(() => {
        this.setBarcodeId(id);
        this.$router.push({
          name: 'places.list',
          query: {
            store_id: this.place.shelf.data.rack.data.store_id,
            rack_id: this.place.shelf.data.rack_id,
            shelf_id: this.place.shelf_id,
          }
        }).catch(() => {console.log()});
      }).catch(error => {
        if (error.response.status === 404) {
          this.$toasted.error(error.response.data.data.message);
        }
      }).finally(() => {
        this.isLoading = false;
      });
    },
    getParcel(id) {
      this.isLoading = true;
      this.fetchParcel(id).then(() => {
        this.$router.push({name: 'orders.show', params: {orderId: this.parcel.order.data.id}}).catch(() => {console.log()});
      }).catch(error => {
        if (error.response.status === 404) {
          this.$toasted.error(error.response.data.data.message);
        }
      }).finally(() => {
        this.isLoading = false;
      });
    },
    getProduct(id) {
      this.isLoading = true;
      this.fetchProduct(id).then(() => {
        this.$router.push({
          name: 'products.show',
          params: {productId: id}
        }).catch(() => {console.log()});
      }).catch(error => {
        if (error.response.status === 404) {
          this.$toasted.error(error.response.data.data.message);
        }
      }).finally(() => {
        this.isLoading = false;
      });
    }
  },
  destroyed() {
    document.removeEventListener('keydown', this.keyEventReading)
  },
}
